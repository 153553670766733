<template>
  <div>
    <div class="header">
      <img src="@/assets/images/header.png" width="100%" />
    </div>
    <div class="navbar szse-navbar szse-navbar-pc">
      <nav
        class="collapse navbar-collapse bs-navbar-collapse navbar-wrap"
        role="navigation"
      >
        <div class="g-container">
          <div class="nav-box bondnav-box">
            <ul class="nav navbar-nav" style="font-family: sans-serif;">
              <li chnlcode="home" class="active">
                <a datapath="home" href="#">HOME</a>
              </li>
              <li chnlcode="listings">
                <a datapath="listings">LISTING</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="whySzse">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="whySzse"
                              >Why MOEX Blue Chip</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="standards">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="standards"
                              >Listing Standards</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="process">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="process">Listing Process</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="listFees">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="listFees">Listing Fees</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="faq">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="faq"
                              >Frequently Asked Questions</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="disclosures">
                <a datapath="disclosures">DISCLOSURE</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="announcements">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="announcements"
                              >Company Announcements</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="profiles_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="profiles_link"
                              >Company Profiles</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="fund">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="fund">Fund Information</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="siteMarketData">
                <a datapath="siteMarketData">MARKET DATA</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="siteMarketDatas">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Market Data</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Stocks</a>

                            <a href="#"><i></i>Funds</a>

                            <a href="#"><i></i>Quotes Lookup</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="marketStatistics">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Market Statistics</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Market Overview</a>

                            <a href="#"><i></i>Securities Summary</a>

                            <a href="#"><i></i>Sector Summary</a>

                            <a href="#"><i></i>Top Counters</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="indices">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Indices</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Indices Performance</a>

                            <a href="#"><i></i>Indices Constituent</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="publication">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Publication</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Monthly Report</a>

                            <a href="#"><i></i>Fact Book</a>

                            <a href="#"><i></i>Research</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="products">
                <a datapath="products">PRODUCTS</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="equity">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Equity</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Overview</a>

                            <a href="#"><i></i>Main Board</a>

                            <a href="#"><i></i>ChiNext</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="lefuFunds">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="lefuFunds">Fund</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="bonds">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="bonds">Bonds</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="security">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="security"
                              >Asset-Backed Securities</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="options">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Options</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Overview</a>

                            <a href="#"><i></i>CSI 300 ETF Options</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="services">
                <a datapath="services">SERVICES</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="trading">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">Trading</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Overview</a>

                            <a href="#"><i></i>Trading Calendar</a>

                            <a href="#"><i></i>Trading Fees</a>

                            <a href="#"><i></i>Margin Trading</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="membership">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="membership"
                              >Membership Services</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="stockConnect_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="stockConnect_link"
                              >Stock Connect</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="qr_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="qr_link">QFII &amp; RQFII</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="dataServices">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="dataServices"
                              >Data Services</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="siteRules">
                <a datapath="rules">RULES</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="siteRule">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="siteRule"
                              >MOEX Blue Chip Rules</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li chnlcode="enAbout">
                <a datapath="about">ABOUT</a>

                <div class="dropdown-menu">
                  <div class="g-container">
                    <div class="g-dropdown-submenu">
                      <div class="g-submenu-item" chnlcode="enAboutOverview">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enAboutOverview">Overview</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enAboutNews">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">News</div>

                          <div class="g-navins-con">
                            <a href="#"><i></i>Speeches</a>

                            <a href="#"><i></i>MOEX Blue Chip News</a>

                            <a href="#"><i></i>New Listings</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enMilestones">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enMilestones">Milestones</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enOurBrand">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enOurBrand">Our Brand</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enOrganization">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enOrganization"
                              >Organization</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enMembers">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enMembers">Members</a>
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enCorporate">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enCorporate"
                              >Corporate Social Responsibility</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enCrossborder_link">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enCrossborder_link"
                              >Cross-border Capital Services</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="g-submenu-item" chnlcode="enContactus">
                        <div class="g-submenu-item-in">
                          <div class="g-navins-tit">
                            <a href="#" chnlcode="enContactus">Contact Us</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div class="navbar-fixed-top navmobile szse-navbar szse-navbar-mobile">
      <div class="mobile-search">
        <div class="mobile-search_container">
          <div class="mobile-search_form">
            <div class="top-search-el">
              <div
                class="search-form sh-searchhint-container"
                style="position: relative;"
              >
                <form action="" target="frameFile" id="searh_form">
                  <input
                    type="search"
                    class="mobile-search_input search-input need-repaired-placeholder"
                    placeholder="Search"
                  />
                  <input type="text" style="display: none;" />
                  <iframe name="frameFile" style="display: none;"></iframe>
                </form>
              </div>
            </div>
            <!-- <input class="mobile-search_input" type="text" name="topbarSearch" placeholder="搜索行情/公告/其他信息" /> -->
            <img
              class="mobile-search_close"
              src="@/assets/images/mobile-search_close.png"
              alt="search close"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="g-locationnav">
      <div class="banner-wrap">
        <img
          src="http://res.static.szse.cn/enweb/common/images/commonbannerbg.jpg"
        />
      </div>
      <div class="g-container">
        <div class="g-banner-txt">
          <h2 class="hidden-xs">MOEX Blue Chip English</h2>
          <p>
            <img
              src="http://res.static.szse.cn/common/icon/er-banneradr.png"
            />LOCATION:
            <a href="./" class="CurrChnlCls">MOEX Blue Chip English</a>
          </p>
        </div>
      </div>
    </div>
    <!--207 -->
    <!-- <div class="indexbanner-wrap en-banner">
    </div> -->
    <div class="component">
      <div class="g-container">
        <h2
          class="news-tit text-center"
          style="font-family: sans-serif;text-shadow: 0 0 20px #000, 0 0 15px #000;color:#fff;letter-spacing:2px;font-weight:bold;"
        >
          MOEX Blue Chip&nbsp;VISA&nbsp;COMPONENT&nbsp;INDEX
        </h2>
        <div class="list-wrap">
          <div tabindex="0" class="c-selectex-container">
            <div class="dropdown">
              <a
                class="btn btn-default c-selectex-btn dropdown-btn"
                data-s-dropdown-id="#c-selectex-menu-2"
              >
                <span
                  tabindex="0"
                  class="c-selectex-btn-text undefined"
                  style="display: block;font-family: sans-serif;"
                  title="MOEX Blue Chip VISA Component Index"
                  >MOEX Blue Chip VISA Component Index</span
                >
                <span class="glyphicon glyphicon-menu-down"></span>
              </a>
              <ul id="c-selectex-menu-2" class="dropdown-menu">
                <li class="active">
                  <a
                    title="MOEX Blue Chip VISA Component Index"
                    data-id="0"
                    data-value="399001"
                    >MOEX Blue Chip VISA Component Index</a
                  >
                </li>
                <li>
                  <a
                    title="MOEX Blue Chip Composite Index"
                    data-id="1"
                    data-value="399106"
                    >MOEX Blue Chip VISA Composite Index</a
                  >
                </li>
                <li>
                  <a title="ChiNext Index" data-id="2" data-value="399006"
                    >ChiNext Index</a
                  >
                </li>
                <li>
                  <a
                    title="MOEX Blue Chip 100 Index"
                    data-id="3"
                    data-value="399330"
                    >MOEX Blue Chip 100 Index</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="index-wrap">
            <div class="index-trend">
              <ul class="pic-tabs">
                <li class="active">
                  <a href="#" style="font-family: sans-serif;">Real-time</a>
                </li>
                <li>
                  <a href="javascript:void(0)" style="font-family: sans-serif;"
                    >Daily</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" style="font-family: sans-serif;"
                    >Weekly</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" style="font-family: sans-serif;"
                    >Monthly</a
                  >
                </li>
              </ul>
              <div class="tabs-content">
                <div
                  id="minutely"
                  class="tab-panel active"
                  _echarts_instance_="ec_1680574159124"
                  style="-webkit-tap-highlight-color: transparent; user-select: none; background: transparent;"
                >
                  <div
                    style="position: relative; overflow: hidden; width: 772px; height: 283px; padding: 0px; margin: 0px; border-width: 0px; cursor: default;"
                  >
                    <canvas
                      width="772"
                      height="283"
                      data-zr-dom-id="zr_0"
                      style="position: absolute; left: 0px; top: 0px; width: 772px; height: 283px; user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); padding: 0px; margin: 0px; border-width: 0px;"
                    ></canvas>
                  </div>
                  <div></div>
                </div>
              </div>
              <span class="update-time">{{
                showDate2(this.last.createdate)
              }}</span>
            </div>
            <div class="index-detail">
              <div
                style="font-family: sans-serif;margin-bottom: 0px;"
                :class="{
                  title: true,
                  down: this.last.change < 0 ? true : false,
                  up: this.last.change >= 0 ? true : false,
                }"
              >
                {{ numberFormat(this.last.last_threeall, true) }}
              </div>
              <div
                style="font-family: sans-serif;padding-top: -0px;padding-bottom: 20px; text-align: center;font-weight: 600;"
              >
                <font
                  size="3"
                  :color="this.last.change0 ? '#22ac38' : '#db3338'"
                  >({{ numberFormat(this.last.last_twoall, true) }} /
                  {{ numberFormat(this.last.change, true) }})</font
                >
              </div>
              <ul>
                <li>
                  <span class="name">Prev.Close</span>
                  <span class="value">{{
                    this.last.threeall2
                      ? numberFormat(this.last.threeall2, true)
                      : this.last.threeall
                      ? numberFormat(this.last.threeall, true)
                      : numberFormat(this.last.open, true)
                  }}</span>
                </li>
                <li
                  :class="{
                    'open-down': this.last.change < 0 ? true : false,
                    'open-up': this.last.change >= 0 ? true : false,
                  }"
                >
                  <span class="name">Open</span>
                  <span class="value change">
                    <span class="value-cont">
                      <i class="icon"></i>
                      {{ numberFormat(this.last.open, true) }}
                    </span>
                  </span>
                </li>
                <li
                  :class="{
                    down: this.last.change < 0 ? true : false,
                    up: this.last.change >= 0 ? true : false,
                  }"
                >
                  <span class="name">Last</span>
                  <span class="value change">
                    <span class="value-cont">
                      <i class="icon"></i>
                      {{ numberFormat(this.last.last_threeall, true) }}
                    </span>
                  </span>
                </li>
                <!-- <li  :class="{'down':(this.last.change<0)?true:false,'up':(this.last.change>=0)?true:false}">
                                <span class="name">Change</span>
                                <span class="value change">
                                    <span class="value-cont">
                                        <i class="icon"></i>
                                        {{ numberFormat(this.last.last_twoall,true) }}
                                    </span>
                                </span>
                            </li>
                            <li style="background-color: #f7f7f7;" :class="{'down':(this.last.change<0)?true:false,'up':(this.last.change>=0)?true:false}">
                                <span class="name">Change（%）</span>
                                <span class="value change">
                                    <span class="value-cont">
                                        <i class="icon"></i>
                                        {{ numberFormat(this.last.change,true) }}
                                    </span>
                                </span>
                            </li> -->
                <li>
                  <span class="name">Day High</span>
                  <span class="value blue">
                    {{ numberFormat(this.last.max, true) }}
                  </span>
                </li>
                <li>
                  <span class="name">Day Low</span>
                  <span class="value blue">
                    {{ numberFormat(this.last.min, true) }}
                  </span>
                </li>
                <li>
                  <span class="name">Volume（10Bil.）</span>
                  <span class="value">{{
                    numberFormat(this.last.volumn10bil, true)
                  }}</span>
                </li>
                <li>
                  <span class="name">Amount（100Mil.）</span>
                  <span class="value">{{
                    numberFormat(this.last.volumnmil, true)
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <a class="loadingBtn"></a>
        </div>
      </div>
    </div>
    <div class="history">
      <h2 style="text-align: center;font-family: sans-serif;">
        MOEX Blue Chip VISA Component Index Historical Data
      </h2>
      <table
        class="tbl"
        data-test="historical-data-table"
        style="--freeze-column-width:100px;"
      >
        <thead class="datatable_head__ysELt" style="font-family: sans-serif;">
          <tr class="datatable_row__qHMpQ">
            <th class="datatable_cell__0y0eu">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white;">Date</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Price</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Open</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">High</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Low</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Vol</span>
              </div>
            </th>
            <th class="datatable_cell__0y0eu datatable_cell--align-end__fwomz">
              <div class="datatable_cell__wrapper__ddKL4">
                <span style="color:white">Change %</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="datatable_body__cs8vJ">
          <tr
            class="datatable_row__qHMpQ"
            style="font-family: sans-serif;"
            data-test="historical-data-table-row"
            v-for="(item, index) in data"
          >
            <td
              class="datatable_cell__0y0eu font-bold"
              style="text-align: center;"
            >
              {{ showDate(item.datetime) }}
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed"
              dir="ltr"
              :style="{ 'text-align': 'right', color: checkColor(item.twoall) }"
            >
              <template v-if="item.threeall">
                {{ numberFormat(item.threeall, true) }} ({{
                  numberFormat(item.twoall, true)
                }})
              </template>
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
              style="text-align: right;"
            >
              {{ numberFormat(item.open, true) }}
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
              style="text-align: right;"
            >
              {{ numberFormat(item.max, true) }}
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
              style="text-align: right;"
            >
              {{ numberFormat(item.min, true) }}
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
              data-test="volume-cell"
              style="text-align: right;"
            >
              {{ numberFormat(item.volumn, true) }}M
            </td>
            <td
              class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed font-bold"
              dir="ltr"
              :style="{ 'text-align': 'right', color: checkColor(item.change) }"
            >
              {{
                item.change.toString().charAt(0) === "-"
                  ? item.change
                  : "+" + item.change
              }}%
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      style="font-family: sans-serif;padding-top: 20px;padding-bottom: 20px; justify-content: center; display: flex;"
    >
      <div
        data-test="analysis-container"
        class="news-analysis_articles-container__SxAEy mobileAndTablet:px-3"
      >
        <div class="layout_row__DCQjA layout_row--space-between__IAcWa mb-3">
          <h2 class="text-2xl font-semibold mb-4 inline-block">
            <a
              class="inv-link inv-link-title large primary"
              data-test="link-analysis"
              href="/indices/rts-standard-opinion"
              ><span class="inv-link-title-text"
                >MOEX Blue Chip VISA Analysis</span
              ><svg
                viewBox="0 0 24 24"
                width="1em"
                fill="none"
                style="height:auto"
                class="inv-link-title-arrows inv-icon-RTL"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.9524 11.5L3.50794 2L2 3.69643L8.93651 11.5L2 19.3036L3.50794 21L11.9524 11.5ZM21 11.5L12.5556 2L11.0476 3.69643L17.9841 11.5L11.0476 19.3036L12.5556 21L21 11.5Z"
                  fill="currentColor"
                ></path></svg
            ></a>
          </h2>
        </div>
        <ul class="" data-test="analysis-list">
          <li
            class="list_list__item__NpIVc news-analysis_articles-list-item__bJ_3h pb-3"
          >
            <article
              data-test="article-item"
              class="news-analysis_article__CdSRG grid gap-3.5 news-analysis_analysis__n_Yjx"
            >
              <figure tabindex="-1" class="pt-0.5">
                <a
                  ><div class="lazyload-wrapper ">
                    <img
                      src="https://d1-invdn-com.investing.com/company_logo/44721_1336029434.jpg"
                      alt=""
                      data-test="item-image"
                      class="news-analysis_article-image__wgIhA"
                    /></div
                ></a>
              </figure>
              <div class="news-analysis_content__vBoNw text-xs">
                <a
                  class="inv-link text-secondary font-bold text-sm whitespace-normal"
                  data-test="article-title-link"
                  href="/analysis/monthly-newsletter--april-2016-edition-94-200123587"
                  >Economy Crawls Along With Low Growth And Inflation</a
                >
                <ul class="list_list--horizontal__35mej flex-wrap mt-0.5">
                  <li
                    class="list_list__item__NpIVc news-analysis_info-item__K_kgL"
                  >
                    <div
                      class="layout_row__DCQjA layout_row--start__41vcp items-center flex-wrap text-common-grey text-2xs mt-0.5"
                    >
                      <span class="mr-1 shrink-0">By</span
                      ><a
                        class="inv-link shrink-0"
                        data-test="article-provider-link"
                        href="/members/contributors/44721"
                        >Yale Bock</a
                      ><span class="mx-1">-</span
                      ><time
                        data-test="article-publish-date"
                        datetime="2016-04-01T06:36:00+0000"
                        class="ml-1 shrink-0"
                        >Apr 02, 2016</time
                      >
                    </div>
                  </li>
                </ul>
                <p
                  data-test="article-description"
                  class="news-analysis_description__A678y overflow-hidden mt-1.5"
                >
                  U.S. Economic and Financial Markets Outlook- Economy Crawls
                  Along With Low Growth and Inflation! (Y H &amp; C Investments
                  may have positions in companies mentioned in this...
                </p>
              </div>
            </article>
          </li>
        </ul>
      </div>
    </div>
    <hr />

    <footer class="enfooter">
      <div class="enfooter-content">
        <div class="g-container" style="font-family: sans-serif;">
          <div class="footer-navlinks hidden-xs" style="text-align: center;">
            <a href="#">LISTING</a>
            <a href="#">DISCLOSURE</a>
            <a href="#">MARKET DATA</a>
            <a href="#">PRODUCTS</a>
            <a href="#">SERVICES</a>
            <a href="#">RULES</a>
            <a href="#">ABOUT</a>
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        <div class="g-container hidden-xs">
          <div class="footersafe-con">
            <img
              id="imgConac"
              src="http://res.static.szse.cn/common/icon/footer-icon-1.png"
            />
            <img
              src="http://res.static.szse.cn/common/icon/footer-icon-2.png"
            />
            <img
              src="http://res.static.szse.cn/common/icon/footer-icon-3.png"
            />
          </div>
          <div class="con" style="font-family: sans-serif;">
            <div class="rule-tip">
              <a class="lawspublishlink" href="#" target="_blank "
                >Disclaimer</a
              >
              &nbsp;
              <a class="copyright-tip" href="#">ICP05012689</a
              ><span
                >©<span class="copyright-year">2023</span> MOEX Blue Chip VISA
                Stock Exchange. All Rights Reserved</span
              >
            </div>
            <div class="ie-tip ">
              IE9.0+，1280*889
            </div>
          </div>
        </div>

        <div class="footer-copyright-mobile visible-xs ">
          <div class="footersafe-con">
            <a
              href="//bszs.conac.cn/sitename?method=show&amp;id=541B4DBFF36A103AE053022819ACAC5A"
            >
              <img
                id="imgConac"
                src="http://res.static.szse.cn/common/icon/footer-icon-1.png"
              />
            </a>
            <a href="http://www.sznet110.gov.cn/netalarm/index.jsp">
              <img
                src="http://res.static.szse.cn/common/icon/footer-icon-2.png"
              />
            </a>
            <a
              href="http://www.sznet110.gov.cn/webrecord/innernet/Welcome.jsp?bano=4403201010119"
            >
              <img
                src="http://res.static.szse.cn/common/icon/footer-icon-3.png"
              />
            </a>
          </div>
          <div class="con">
            <a class="lawspublishlink" href="#" target="_blank ">Disclaimer</a>
            &nbsp;<a href="#">ICP05012689</a> <br />©<span
              class="copyright-year"
              >2023</span
            >
            MOEX Blue Chip VISA Stock Exchange.
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import moment from "moment-timezone";
import axios from "@/axios.ts";

export default {
  name: "Home",
  data() {
    return {
      last: [],
      status: "*Close",
      date_show: "",
      data_price: 0,
      data_prev: 0,
      chart_data: [],
      ChartPlot: "",
      data_label: [],
      data_time: [],
      first: "",
      data: [],
      picupdata: [],
    };
  },
  mounted() {
    // this.getBackGround()
    // this.getLotto()
    // this.getBanners()
    // $("#idx_carousel").carousel({
    //   interval: 10000,
    // });
    this.date_show = moment()
      .tz("Asia/Bangkok")
      .format("MMM/DD/YYYY");
    this.getLotto();
    // this.getLast()
  },

  created() {
    // this.date_show = moment().tz('Asia/Bangkok').format('MMM/DD/YYYY')
    // this.getLotto()
    // this.getLast()
    // setInterval(() => {
    //   this.getLotto()
    //   this.getLast()
    // }, 10000)
  },
  methods: {
    checkColor(num) {
      if (num) {
        if (Number(num) < 0) {
          return "red";
        } else {
          return "#0ea600";
        }
      }
    },
    numberFormat(x, decimal) {
      if (x) {
        if (decimal) {
          return Number(x).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        } else {
          return Number(x).toLocaleString(undefined);
        }
      }
    },
    showDate(datetime) {
      return datetime.split(" ")[0];
    },
    showDate2(datetime) {
      return moment(datetime).format("DD-MM-YYYY HH:mm");
    },
    async getLotto() {
      await axios.get("/list-v2/13").then((response) => {
        // this.data = response.data
        const data = response.data;
        if (data) {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const d = element.datetime.split(" ");
            const date = d[0].split("-");
            const time = d[1].split(":");
            const datetime = Number(
              date[0] +
                "" +
                date[1] +
                "" +
                date[2] +
                "" +
                time[0] +
                "" +
                time[1]
            );
            if (
              moment(element.datetime)
                .add(-4, "hours")
                .tz("Asia/Bangkok")
                .format("YYYYMMDDHHmm") >
              Number(
                moment()
                  .tz("Asia/Bangkok")
                  .format("YYYYMMDDHHmm")
              )
            ) {
              continue;
            }
            this.data.push(element);
          }
        }
        setInterval(() => {
          this.getLast();
        }, 1000);
      });
    },
    async getLast() {
      // label = 6
      const price = [];
      let date;
      if (
        Number(
          moment()
            .tz("Asia/Bangkok")
            .format("HHmm")
        ) <= 1900
      ) {
        date = moment()
          .tz("Asia/Bangkok")
          .add(-1, "days")
          .format("YYYY-MM-DD");
      } else {
        date = moment()
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DD");
      }
      const n = {
        indexDetailTmp: $("#indexDetail").html(),
        minuteWrap: $("#minutely"),
        dayWrap: $("#daily"),
        weekWrap: $("#weekly"),
        monthWrap: $("#monthly"),
        trendTabs: $(".index-trend .pic-tabs"),
        code: "399001",
        charts: {},
        chartDataState: {},
      };
      this.picupdata = [];
      await axios.get("/moexbluechip-chart").then((response) => {
        const data = response.data;
        let min = 0;
        let max = 0;

        for (let index = 0; index < data.length; index++) {
          const element = data[index];

          if (element) {
            element.createdate = moment(element.createdate)
              .add(-4, "hours")
              .tz("Asia/Bangkok")
              .format("YYYY-MM-DD HH:mm:ss");
            this.last = element;
            const d = moment(element.updatedate)
              .add(-4, "hours")
              .tz("Asia/Bangkok")
              .format("YYYY-MM-DD HH:mm:ss");
            const createdate1 = d.split(" ")[1];
            const time = createdate1.split(":");
            const time0 = Number(time[0])
              .toString()
              .padStart(2, "0");
            const amount100 = element.amount100mil.toString().split(".");
            const amount100mil = amount100[0] + "000";
            const amount100m2 = element.amount100m.toString().split(".");
            const amount100m = amount100m2[0] + amount100m2[1] + "027755.29";
            if (element.last_threeall) {
              this.picupdata.push([
                time0 + ":" + time[1],
                Number(element.last_threeall)
                  .toFixed(2)
                  .toString(),
                element.last_twoall.toFixed(2).toString(),
                element.change.toFixed(2).toString(),
                Number(amount100mil),
                // 3697911.0,
                Number(amount100m),
                // 5788027755.29
              ]);
            }
            min = element.min;
            max = element.max;
            // picupdata.push(
            // [
            //       "09:30",
            //       "11878.16",
            //       "-11.26",
            //       "-0.09",
            //       3697911.0,
            //       5788027755.29
            // ])
          }
        }
        const e = {
          datetime: moment()
            .tz("Asia/Bangkok")
            .add(1, "hours")
            .format("YYYY-MM-DD HH:mm"),
          code: "0",
          data: {
            code: "399001",
            name: "深证成指",
            close: this.last.last_threeall,
            marketTime: moment()
              .tz("Asia/Bangkok")
              .add(1, "hours")
              .format("YYYY-MM-DD HH:mm"),
            picupdata: this.picupdata,
            releInfo: {
              cfzqlb: "",
              zsdm: "399001",
              zslx: "",
              cfzqsclb: "",
            },
          },
          message: "成功",
        };
        var t = { data: e };
        if ($.isEmptyObject(n.charts.minuteChart)) {
          var a = new s.ui.controls.mLineChart(n.minuteWrap, t);
          n.charts.minuteChart = a;
        } else {
          n.charts.minuteChart.updateOption(t);
        }
      });
    },
  },
};
</script>

<style scoped>
.tbl {
  border-collapse: collapse;
  width: 94%;
  margin-left: 3%;
  margin-ringht: 3%;
}
.tbl th,
.tbl td {
  padding: 5px;
  border: solid 1px #777;
}
.tbl th {
  background-color: #9c061b;
}
</style>
